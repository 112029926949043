import stringTemplateFormat from './string/stringTemplateFormat';
import { setLanguage } from '@plecto/components';
import locales from '../utils/locale/index';
import 'moment/locale/da';
import 'moment/locale/de';
import moment from 'moment-timezone';

/** @type {Object} */
const allTranslations = { ...locales };

/** @type {{
	activeLanguage: string,
	availableLanguages: string[],
	setLanguage: (lang: string) => void,
	setLanguageFromTag: () => void,
	getTranslation: (str: string, context?: any) => string
}} */
const Translations = {
	activeLanguage: 'en',
	availableLanguages: ['en', 'da', 'de'],

	setLanguage: function(lang) {
		if (this.availableLanguages.indexOf(lang) >= 0) {
			this.activeLanguage = lang;
		} else {
			this.activeLanguage = 'en';
			if (process.env.NODE_ENV !== 'test') {
				console.warn(`Language "${lang}" is not supported!`);
			}
		}

		moment.locale(this.activeLanguage);
		setLanguage(this.activeLanguage);
	},

	setLanguageFromTag: function() {
		this.setLanguage(document.documentElement.lang);
	},

	getTranslation: function(str, context) {
		// Fetch translations for the active language or fall back to English
		const langTranslations = allTranslations[this.activeLanguage] || allTranslations['en'];
    
		// Get the translation string or fall back to the key itself
		let template = langTranslations?.[str] || str;

		if (!langTranslations?.[str]) {
			//warn about missing translations
			console.warn(`Missing translation for key: "${str}" in language: ${this.activeLanguage}`);
			template = str; //fallback to key
		}    
		// Format the string with placeholders (if any)
		return stringTemplateFormat(template, context);
	},
};

const _t = function(str, context) {
	if (document.documentElement.lang !== Translations.activeLanguage) {
		Translations.setLanguageFromTag();
	}
	return Translations.getTranslation(str, context);
};

export { _t, Translations };
